<template>
  <main class="">

    <section class="grid-sect2 main  ">
        <div class="video-container2" >


  <iframe class="video-content" width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/cFz7vWa2P64?controls=0&autoplay=1&enablejsapi=1&rel=0&loop=1&showinfo=0&modestbranding=0&iv_load_policy=3&color=white&mute=1&playlist=cFz7vWa2P64"   allow="accelerometer; autoplay; "  allowfullscreen></iframe>

</div>
 
 </section>
  
<div class="-flex-c" >

 <div class="info-container">
<p class="sect-title maj" >Bleach
</p>
<p > 
  SŌDĒ is an experiment with a software that I enjoy a lot currently. <br> I wanted to play with the tones and colours to give an animated and vivacious visual. 
</p> 
<!-- <br>In punishment, Zeus condemns him to be chained to a rock and have his liver devoured by an eagle every day. -->
</div>
<div class="detail-projet">
<p class="sect-title maj">2023</p>
<p class=" gras maj"> <span> EXPERIMENTATION </span></p>

<!-- <p><a  style="border-bottom:solid 5px #A2A6A8 ;" href="" target="_blank" rel="noopener noreferrer"> <span class="gras">Listen the demo</span> </a></p> -->
</div>

</div>

<section class="sect-page --black -flex-m  ">

<div class="block-container1 -flex-m" >

           <img v-img class="cover-img3 gap-img" :src="require ('@/assets/Sode/cover1.jpg')" alt=" "> 
           <img v-img class="cover-img3 " :src="require ('@/assets/Sode/cover3.jpg')" alt=" "> 

</div>

</section>





<div class="option-navigation">

 <h1> <a href="#top" class="btn-selection">TOP</a></h1>

</div>
<Footer/>
  </main>
</template>

<script>

 import Footer from "@/components/footer.vue"
 // import Vue from 'vue';
 // import VueImg from 'v-img';
 // Vue.use(VueImg);

 export default {
  components:{
  
  Footer,
},
mounted() {
    // Get the current reload count from localStorage, default to 0
    let reloadCount = parseInt(localStorage.getItem('reloadCount')) || 0;

    // Check if the page can be reloaded again
    if (reloadCount < 2) {
      // Increment the reload count
      reloadCount++;

      // Save the updated count to localStorage
      localStorage.setItem('reloadCount', reloadCount);

      // Set a delay of 2 seconds before reloading the page
      setTimeout(() => {
        // Reload the page after the delay
        location.reload();
      }, 2000); // Adjust the delay in milliseconds (2 seconds in this example)
    }

    // Reset the scroll position to (0,0)
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="css" scoped>

.video-container{
 grid-area: top/aside1/foot/main-end;
}
.video-container2{
 grid-area: top/aside1/foot/main-end;
 height: 100vh;
}
.video-content{
   /* border-radius: 30px; */
width: 100%;
}
.grid-sect, .grid-sect2 img{
 /* object-fit: contain; */
}
.retour-info{
 grid-area: foot;
}
.img-flex{
   display: flex;
   flex-direction: row;
   /* flex-wrap: wrap; */
   justify-content: center;
   /* align-items: center; */
 
}
.img-bg2{
margin-bottom: 30px;
   height: 95%;
   width: 80%;
}
.img-bg{
margin-bottom: 30px;
   height: 100%;
   width: 96%;
}
.conception-container{
   grid-area: foot;
}

.block-container0{
   grid-row: top / aside3;
   grid-column: aside1 / main-end;
    /* padding: 15px 10px 0 10px;  */
 
   /* margin: auto; */
}
.block-container1{

  grid-row: aside1 / aside2;
grid-column: main-row1 / main-mid;
     /* padding: 15px 10px 0 0.4rem;   */
 
   /* margin: auto; */
}
.block-container2{
   grid-row: top / aside1;
   grid-column: main-mid / main-end;
    /* padding: 15px 10px 0 10px;  */

   /* margin: auto; */
}
.block-container3{
   grid-row: aside2 / aside3;
   grid-column: aside2 / main-row3;
    /* padding: 15px 10px 0 10px;  */
 
   /* margin: auto; */
}
.block-container4{
   grid-row: aside2 / aside3;
   grid-column: main-mid / main-end;
    /* padding: 15px 10px 0 10px;  */

   /* margin: auto; */
}
.reflexion-info{
   grid-area: foot;
}


.memento-bg{
 background-image:url('~@/assets/MementoMori/theroom.jpg');
grid-area: top/top/aside4/main-end;

}

.mockup-img{
width: 100%;
height: 80%;

}

.full-img{
 margin-top: 0.2em;
 display: block;
   width: calc(100% + 2px);
   /* height: 100%; */
   height: calc(100% + 2px);
 /* height: 85vh; */
}
.flexR{
 display: flex;
 /* width: 50%; */
}
.grid-sect2{
       
       display: grid;
       position: relative;
       grid-template-columns: repeat(4,1fr);
       grid-template-rows: repeat(4, 1fr);
       grid-column-gap: 0.2em;
       grid-row-gap: 0.2em;
       grid-template-areas: 
       "top top top  top" 
       "aside1 main-row1 main-mid  main-end"
        "aside2 main-row2 main-mid  main-end" 
        "aside3 main-row3 main-mid   main-end"
       ;
       
       }
       @media only screen and (max-width: 1600px){

               .grid-sect2{
       grid-template-columns: repeat(4, 1fr);
       
       grid-template-areas: 
       "top top top top-end" 
       "aside1 main-row1 main-mid main-end"
        "aside2 main-row2 main-mid main-end" 
        "aside3 main-row3 main-mid main-end"
        
         "foot foot foot foot"
       ;
       } 

        .grid-sect{
       grid-template-columns: repeat(4, 1fr);
       
       grid-template-areas: 
       "top top top top-end" 
       "aside1 main-row1 main-mid main-end"
        "aside2 main-row2 main-mid main-end" 
        "aside3 main-row3 main-mid main-end"
        "aside4 main-row4 main-mid main-end"
         "foot foot foot foot"
       ;
       } 
      
       .block-container{
       
       align-self: center;
       justify-content: center;
       }
       .cover-img{
         /* height: 80vh; */
       }
       .flexR{
         flex-direction: row;
         width: 100%;
         justify-content:center;
       }


       }
         /*Je creer la grille pour les ecrans > à 1005px */
       @media only screen and (max-width: 1185px){

            /* .grid-sect2{
       grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 0.5fr);
       grid-template-areas: 
       "top top top top" 
       "aside1 main-row1 main main-end"
        "aside2 main-row2 main main-end" 
        "aside3 main-row3 main main-end"
      
       
       ;
       }  */
        /* .grid-sect{
       grid-template-columns: repeat(3, 1fr);
       
       grid-template-areas: 
       "top top top" 
       "aside1 main-row1 main-end"
        "aside2 main-row2 main-end" 
        "aside3 main-row3 main-end"
        "aside4 main-row4 main-end"
         "foot foot foot"
       ;
       }  */
       .block-container{
           
         justify-content: center;
      

       }
       .flexR{
          /* flex-direction: column; */
       }
       .cover-img{
       /* height: 33vh; */
       }

       }

 /*Je creer la grille pour les ecrans < à 735px */
       @media only screen and (max-width: 735px){
    
     .grid-sect2{
       display: block;

       grid-template-columns: repeat(2,1fr);
        grid-template-rows:  repeat(4, 0.5fr);
       grid-template-areas: 
       "top top  " 
       "aside1  main-row1"
        "aside2  main-row2" 
   
         "foot foot  "
       ;
       } 
.grid-sect2 > div {
 /* margin-bottom: 5px; */
}

        .grid-sect{
       grid-template-columns: repeat(2,1fr);       
       grid-template-areas: 
       "top top  " 
       "aside1  main-row1"
        "aside2  main-row2" 
        "aside3  mmain-row3"
        "aside4  main-row4"
         "foot foot  "
       ;
       } 

.block-container{
    grid-area: top/aside2/foot/main-row1;
}

.img-flex{
   flex-direction: column;
}
.video-container{
 display: block;
/* grid-area: top/aside2/foot/main-row4; */
}
.video-container{
height: 100vh;
 display: block;
/* grid-area: top/aside2/foot/main-row4; */
}
.cover-img2{
  width: calc(100% + 2px); }

}
   
 /*Je creer la grille pour les ecrans < à 600px */
       @media only screen and (max-width: 600px) {
         body{
           padding: 0;
         }
         .grid-sect2{
       grid-template-columns: 1fr;
       grid-template-rows: 0.2fr  repeat(2, 0.5fr) 0.4fr;
       grid-row-gap: 2rem;
       grid-template-areas: 
       "top"
       "aside "
       " main-row1"
       "foot"
       ;}


       .grid-sect{
       grid-template-columns: 1fr;
       grid-template-rows: 0.2fr 0.5fr repeat(4, .5fr) 0.4fr;
       grid-row-gap: 2rem;
       grid-template-areas: 
       "top"
       "aside "
       " main-row1"
       " main-row2"
       "section-row1 "
       "section-row2 "
       "foot"
       ;
       }

       .block-container{
   grid-row: top/foot;
   grid-column: span 1;
 
}
section > .video-container2{
          height: inherit;
        }
.video-container{
  grid-area: foot;
}
.video-container{
height: 35vh;
 display: block;
/* grid-area: top/aside2/foot/main-row4; */
}
.flexR{
  flex-direction: column;
  justify-self: center;
}
.cover-img{
 /* height: 40vh;
 margin: 5px; */
}
       }
</style>