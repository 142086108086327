import { render, staticRenderFns } from "./Sode.vue?vue&type=template&id=3b5c4bb7&scoped=true&"
import script from "./Sode.vue?vue&type=script&lang=js&"
export * from "./Sode.vue?vue&type=script&lang=js&"
import style0 from "./Sode.vue?vue&type=style&index=0&id=3b5c4bb7&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b5c4bb7",
  null
  
)

export default component.exports